var app;
(function (app) {
    var core;
    (function (core) {
        var errorHandler;
        (function (errorHandler) {
            'use strict';
            var ErrorHandler = /** @class */ (function () {
                /* @ngInject */
                ErrorHandler.$inject = ["$uibModal"];
                function ErrorHandler($uibModal) {
                    this.$uibModal = $uibModal;
                    //this.displayError({error:'soma erro data here'});
                }
                ErrorHandler.prototype.displayError = function (data) {
                    console.log('this.$uibModal', this.$uibModal);
                    var modalInstance = this.$uibModal.open({
                        animation: true,
                        windowClass: 'ng-show',
                        size: 'lg',
                        //modalTemplate: '<div class="modal modal-width-override" ng-transclude></div>',
                        template: this.modalTmplate(data),
                        // controller: 'ModalInstanceCtrl',
                        //  controllerAs: '$ctrl',
                        //  size: size,
                        // appendTo: parentElem,
                        resolve: {
                            items: function () {
                                return 'this is the text';
                            }
                        }
                    });
                };
                ErrorHandler.prototype.modalTmplate = function (obj) {
                    return "\n                <div class=\"error-message-modal p-3\">\n                    <div class='container'>\n                            <div class='row justify-content-center'>\n                                <div class='col'>\n                                   <pre> " + JSON.stringify(obj) + "</pre>\n                                </div>\n                            </div>\n                    </div> \n                </div>";
                };
                return ErrorHandler;
            }());
            errorHandler.ErrorHandler = ErrorHandler;
            angular
                .module('app.core.errorHandler', []);
            angular
                .module('app.core.errorHandler')
                .service('errorHandler', ErrorHandler);
        })(errorHandler = core.errorHandler || (core.errorHandler = {}));
    })(core = app.core || (app.core = {}));
})(app || (app = {}));
